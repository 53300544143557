<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 15"
  >
    <path
      stroke="#0063C2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M4.21 6.862a1.312 1.312 0 1 0 0 1.276m0-1.276a1.307 1.307 0 0 1 0 1.276m0-1.276 5.58-3.1M4.21 8.139l5.58 3.1m0 0a1.312 1.312 0 1 0 2.295 1.275 1.312 1.312 0 0 0-2.295-1.275Zm0-7.475a1.312 1.312 0 1 0 2.294-1.275A1.312 1.312 0 0 0 9.79 3.763Z"
    />
  </svg>
</template>
